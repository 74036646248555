export const tailwindColors = {
  black: 'hsl(var(--black))',
  white: 'hsl(var(--white))',

  border: 'hsl(var(--border))',

  background: 'hsl(var(--background))',
  foreground: 'hsl(var(--foreground))',
  primary: {
    DEFAULT: 'hsl(var(--primary))',
    alt: 'hsl(var(--primary-alt))',
    foreground: 'hsl(var(--primary-foreground))',
  },
  card: {
    DEFAULT: 'hsl(var(--card))',
    // foreground: 'hsl(var(--card-foreground))',
    foreground: 'hsl(var(--pinkki))',
  },
  destructive: {
    DEFAULT: 'hsl(var(--destructive))',
    // foreground: 'hsl(var(--destructive-foreground))',
    foreground: 'hsl(var(--pinkki))',
  },
  muted: {
    DEFAULT: 'hsl(var(--muted))',
    alt: 'hsl(var(--muted-alt))',
    foreground: 'hsl(var(--muted-foreground))',
  },

  // NOT USED
  // input: 'hsl(var(--input))',
  input: 'hsl(var(--pinkki))',
  accent: {
    DEFAULT: 'hsl(var(--accent))',
    foreground: 'hsl(var(--accent-foreground))',
  },
  secondary: {
    // DEFAULT: 'hsl(var(--secondary))',
    DEFAULT: 'hsl(var(--pinkki))',
    // DEFAULT: 'hsl(var(--secondary))',
    foreground: 'hsl(var(--pinkki))',
  },
  // muted: {
  // DEFAULT: 'hsl(var(--muted))',
  // foreground: 'hsl(var(--muted-foreground))',
  // foreground: 'hsl(var(--pinkki))',
  // },
  popover: {
    // DEFAULT: 'hsl(var(--popover))',
    DEFAULT: 'hsl(var(--pinkki))',
    // foreground: 'hsl(var(--popover-foreground))',
    foreground: 'hsl(var(--pinkki))',
  },
};

export const tailwindBorderRadius = {
  sm: 'calc(var(--radius) - 2px)',
  DEFAULT: 'var(--radius)',
  md: 'calc(var(--radius) + 2px)',
  lg: 'calc(var(--radius) + 4px)',
  xl: 'calc(var(--radius) + 10px)',
  '2xl': 'calc(var(--radius) + 14px)',
  '3xl': 'calc(var(--radius) + 20px)',
};

export const tailwindScreens = {
  tablet: '640px', // => @media (min-width: 640px) { ... }
  laptop: '1024px', // => @media (min-width: 1024px) { ... }
  desktop: '1280px', // => @media (min-width: 1280px) { ... }
};
